import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import discussionImg from "../Assets/Images/img7_1.png";

const Section5 = (props) => {
  return (
    <div className="section5-parent container-fluid">
      <div className="section5-container container pt-5 pb-5">
        <div className="section5-body sec5-grid spy-1">
          <div className="left-content-part flex-column" data-aos="fade-up">
            <h2 className="content-part-heading fw-bold fs-1">
              Never Let <span className="text-grn">Revenue</span> Slip Through
              the Cracks Again!
            </h2>
            <p className="content-tag">Enhance Your Profits</p>
            <div className="perk">
              <div className="perk-txt fw-bold fs-5">
                Illuminate Hidden Metrics
              </div>
              <div className="perk-txt fw-bold fs-5">
                Tap into Customer Insights
              </div>
              <div className="perk-txt fw-bold fs-5">
                Outsmart the Competition
              </div>
            </div>
            <br />
            <button
              className="button explore-btn"
              type="button"
              onClick={() => {
                window.open("https://demo.visualcogs.com", "_blank");
              }}
            >
              Explore Our Hands-On Demo
            </button>
          </div>
          <div className="right-img-part">
            <img
              data-aos="zoom-in"
              src={discussionImg}
              className="section5-main-img"
              alt="graphics"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section5)
);
