import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import header from "../Assets/Images/img1_1.png";

const Hero = (props) => {
  return (
    <div className="header-grid reverse-grid spy-1">
      <div className="header-home col-10 col-lg-12 mx-auto">
        <h1
          className="text-start fs-0 header-text mt-5 mb-4 fw-bold"
          data-aos="fade-up"
        >
          Vendor Central Data,
          <br />
          {""}
          <span className="highlight-text">Streamlined</span>
        </h1>
        <h2
          className="text-start header-subtitle mb-4 fs-5 text-wrap w-80"
          data-aos="fade-up"
        >
          Turnkey Data Visualizations & Insights for Amazon Vendors
        </h2>
        {/* button part  */}
        <div className="button-part mb-5 mt-3 text-start">
          {props.isLoggedIn ? (
            <button
              className="button w-100 bolder-font explore-btn w-80"
              data-aos="fade-up"
              onClick={() => {
                window.open("https://console.visualcogs.com", "_blank");
              }}
            >
              Open Console
            </button>
          ) : (
            <>
              <button
                className="button w-100 bolder-font text-center explore-btn w-80 d-block"
                data-aos="fade-up"
                onClick={() => {
                  window.open("https://demo.visualcogs.com", "_blank");
                }}
              >
                Explore Our Hands-On Demo
              </button>
              <p className="text-muted text-start mt-2 d-block ps-1_5">
                No Sign-up or Credit Card Needed
              </p>
            </>
          )}
        </div>
      </div>
      <div data-aos="zoom-in">
        {" "}
        <img src={header} className="mt-6rem" alt="laptop"></img>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Hero));
