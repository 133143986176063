import React from "react";
import { BackendAPIs, getBaseApi, postCall } from "../../constants";
import MyAccordion from "./MyAccordion";
class Accordion extends React.Component {
  state = {
    questions: [],
  };

  componentDidMount() {
    postCall(getBaseApi() + BackendAPIs.generic.getFAQs, {}).then((resp) => {
      this.setState({ questions: resp.FAQs });
    });
  }

  render() {
    return (
      <div className="section2-parent container-fluid bg-grn">
        <div className="section2-container container pt-5 pb-5">
          <div id="faq" data-aos="fade-up" className="spy-1">
            <div className="accordion-title">FAQs</div>
            <div>
              {this.state.questions.map((curElem, i) => {
                return <MyAccordion key={i} element={curElem} />;
              })}
            </div>
          </div>
          <div className="sec6-flex-end spy-1">
            <button
              className="button explore-btn"
              type="button"
              onClick={() => {
                window.open("https://demo.visualcogs.com", "_blank");
              }}
            >
              Explore Our Hands-On Demo
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Accordion;
