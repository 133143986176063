import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import img from "../Assets/Images/img12_1.png";

const Section11 = (props) => {
  return (
    <div className="section11-parent container-fluid">
      <div className="section11-container container pt-5 pb-5">
        <div className="section11-layout spy-1">
          <div className="sec11-grid bg-grn">
            <div className="side-heading" data-aos="fade-up">
              <h2 className="fs-0_5 fw-bold">
                Expedite Decision Making & Boost Profits Today!
              </h2>
              <br />
              <button
                className="button explore-btn"
                type="button"
                onClick={() => {
                  window.open("https://demo.visualcogs.com", "_blank");
                }}
              >
                Explore Our Hands-On Demo
              </button>
            </div>
            <div className="sec12-img" data-aos="zoom-in">
              <img
                src={img}
                className="section11-img scale1_2"
                alt="graphics"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section11)
);
