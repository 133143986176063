import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import chart1 from "../Assets/Images/img8_1.png";
import chart2 from "../Assets/Images/img8_2.png";
import chart3 from "../Assets/Images/img8_3.png";
import chart4 from "../Assets/Images/img8_4.png";
import chart5 from "../Assets/Images/img8_5.png";
import chart6 from "../Assets/Images/img8_6.png";

const Section6 = (props) => {
  return (
    <div className="section6-parent container-fluid sec6-bg">
      <div className="section6-container container pt-5 pb-5">
        <div className="section6-layout flex-column spy-1">
          <div
            className="section6-header-portion flex-column text-center"
            data-aos="fade-up"
          >
            <h2 className="fs-0 mb-3 fw-bold">
              What's Under the Hood of Visual COGS?
            </h2>
            <h2 className="fs-1 fw-bold mb-2">
              100+ Interactive Charts and Graphs
            </h2>
            <h2 className="fs-1 fw-bold mb-2">20+ Comprehensive Dashboards</h2>
            <p className="fs-5 fw-bold">
              All Engineered to Drive Profit Boosting Decisions
            </p>
          </div>
          <div className="sec6-grid">
            <img
              data-aos="zoom-in"
              src={chart1}
              className="section6-chart-img"
              alt="graphics"
            ></img>
            <img
              data-aos="zoom-in"
              src={chart2}
              className="section6-chart-img"
              alt="graphics"
            ></img>
            <img
              data-aos="zoom-in"
              src={chart3}
              className="section6-chart-img"
              alt="graphics"
            ></img>
            <img
              data-aos="zoom-in"
              src={chart4}
              className="section6-chart-img"
              alt="graphics"
            ></img>
            <img
              data-aos="zoom-in"
              src={chart5}
              className="section6-chart-img"
              alt="graphics"
            ></img>
            <img
              data-aos="zoom-in"
              src={chart6}
              className="section6-chart-img"
              alt="graphics"
            ></img>
          </div>
          <br />
          <div className="sec6-flex-end">
            <button
              className="button explore-btn"
              type="button"
              onClick={() => {
                window.open("https://demo.visualcogs.com", "_blank");
              }}
            >
              Explore Our Hands-On Demo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Section6)
);
